import { useState, useRef, useMemo, useCallback, memo } from "react";
import { Box } from "@material-ui/core";

import api from "../../../../../../services/api";
import Modal from "../../../../../../components/Modal";
import Button from "../../../../../../components/Button";
import TextArea from "../../../../../../components/TextArea";
import useToggle from "../../../../../../hooks/useToggle";
import AlertModal from "../../../../../../components/AlertModal";
import LoadingScreen from "../../../../../../components/LoadingScreen";
import { getDateLimits } from "../../../actions";
import { usePriceManagement } from "../../../../hooks/usePriceManagement";

import EditionsTable from "./EditionsTable";
import moment from "moment";

const ApproveButton: React.FC<any> = ({ tableData }) => {
  const { tabRequest, isUserSupport } = usePriceManagement();

  const [isOpen, toggleOpen] = useToggle();
  const [loading, setLoading] = useState(false);
  const [observation, setObservation] = useState("");

  const errorRef = useRef<any>(null);
  const successRef = useRef<any>(null);
  const errorNoObservationRef = useRef<any>(null);

  const toggleError = useCallback(() => {
    errorRef.current.toggle();
  }, []);

  const toggleSuccess = useCallback(() => {
    successRef.current.toggle();
  }, []);

  const toggleErrorNoObservation = useCallback(() => {
    errorNoObservationRef.current.toggle();
  }, []);

  const validPrices = useMemo(() => {
    return tableData.filter(
      (item: any) =>
        item.validation === "valid" && !/aguardando/i.test(item.order_status)
    );
  }, [tableData]);

  const invalidPrices = useMemo(() => {
    return tableData.filter(
      (item: any) =>
        item.validation === "invalid" && !/aguardando/i.test(item.order_status)
    );
  }, [tableData]);

  const getDtFimDefault = useCallback(
    (dt_inicio) => {
      const [, dt_fim_default] = getDateLimits(tabRequest, dt_inicio);
      return dt_fim_default;
    },
    [tabRequest]
  );

  const sendToApprove = useCallback(() => {
    if (observation.length < 4) {
      return toggleErrorNoObservation();
    }

    setLoading(true);

    const [dt_inicio_default] = getDateLimits(tabRequest);

    // Client wants same day + 1 on start date but allow input same day + 0 so I came up with this working gambiarra:
    // Basically the system stills works on same day + 0 but I updated the input placeholder
    // and approve button only to same day + 1. I mean, it works, don't judge me.
    // Look for this same comment on vscode to find all places implemented.
    const dtInicioDefault = tabRequest.nivel.match(/N1/i)
      ? moment(dt_inicio_default, "DD/MM/YYYY")
          .add(1, "days")
          .format("DD/MM/YYYY")
      : dt_inicio_default;

    let request: any;

    if (tabRequest.requestId) {
      request = {
        id: tabRequest.serviceOrder,
        request_id: tabRequest.requestId,
        level: tabRequest.nivel,
        condition: tabRequest.condition,
        reason: observation,
      };
    } else {
      request = validPrices.map((item: any) => ({
        level: tabRequest.nivel,
        id_os: tabRequest.serviceOrder,
        condition: tabRequest.condition,
        reason: observation,
        id_table: item.id,
        new_value: item.newPrice,
        filial_id_user: item.regiao_vendas,
        dt_fim: item.dt_fim || getDtFimDefault(item.dt_inicio),
        dt_inicio: item.dt_inicio || dtInicioDefault,
      }));
    }

    setTimeout(() => {
      api
        .put("/price", request)
        .then(() => toggleSuccess())
        .catch(() => toggleError())
        .finally(() => setLoading(false));
    }, 1000);
  }, [
    tabRequest,
    observation,
    validPrices,
    toggleError,
    toggleSuccess,
    getDtFimDefault,
    toggleErrorNoObservation,
  ]);

  if (isUserSupport || validPrices.length === 0)
    return <div style={{ height: 25 }} />;
  return (
    <>
      <Button
        onClick={toggleOpen}
        containerStyle={{
          border: "1px solid green",
          marginLeft: "auto",
          backgroundColor: "green",
        }}
      >
        Enviar Para Aprovação
      </Button>
      <Modal
        open={isOpen}
        setOpen={toggleOpen}
        children={
          <>
            {loading ? (
              <div style={{ height: 500 }}>
                <LoadingScreen />
              </div>
            ) : (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    margin: "0 auto",
                    width: "100%",
                    maxWidth: "650px",
                  }}
                >
                  {invalidPrices.length > 0 && (
                    <div
                      style={{
                        width: "100%",
                        color: "red",
                        fontWeight: "bold",
                        textAlign: "center",
                        marginBottom: "1rem",
                      }}
                    >
                      <h2>
                        Atenção! Você possui {invalidPrices.length}{" "}
                        {invalidPrices.length > 1
                          ? "linhas inválidas."
                          : "linha inválida."}
                      </h2>
                      <h2>
                        Caso prossiga linhas inválidas automaticamente serão
                        ignoradas ao enviar para aprovação.
                      </h2>
                    </div>
                  )}
                  <TextArea
                    name="observation"
                    label="Motivo de alteração de preço"
                    value={observation}
                    onChange={(e: React.FormEvent<HTMLTextAreaElement>) =>
                      setObservation(e.currentTarget.value)
                    }
                  />
                  <Button
                    onClick={sendToApprove}
                    containerStyle={{ margin: "1rem 0", maxWidth: 200 }}
                  >
                    Enviar Para Aprovação
                  </Button>
                </Box>
                <EditionsTable newPricesOnlyTableData={validPrices} />
              </>
            )}
          </>
        }
      />
      <AlertModal
        ref={errorRef}
        type="error"
        message="Erro, tente novamente."
      />
      <AlertModal
        ref={errorNoObservationRef}
        type="error"
        message="Por favor insira um motivo de alteração de preço."
      />
      <AlertModal
        ref={successRef}
        message="Preços enviados para aprovação com sucesso!"
        onContinue={() => window.location.reload()}
      />
    </>
  );
};

export default memo(ApproveButton);
