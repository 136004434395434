import { memo, useMemo } from "react";

import Select from "../../../../../../components/Select";
import { usePriceManagement } from "../../../../hooks";
import { useUpload } from "../../../hooks";

import { getLabelText } from "./actions";
import { conditions, niveis, nonHqConditions, nonHqNiveis } from "./constants";
import { Container } from "./styles";

const SelectConditionNivel: React.FC = () => {
  const { isUserSDP, isUserHQ } = usePriceManagement();
  const { nivel, setNivel, segmento, setSegmento, condition, setCondition } =
    useUpload();

  const conditionOptions = useMemo(() => {
    let options = isUserHQ ? conditions : nonHqConditions;

    if (isUserSDP) {
      options = options.filter((opt) => ["Y500", "Y501"].includes(opt));
    }

    setCondition(options[0]);

    return options.map((item) => ({
      key: item,
      label: getLabelText(item),
      value: item,
    }));
  }, [isUserHQ, isUserSDP, setCondition]);

  const nivelOptions = useMemo(() => {
    let options: string[] =
      (isUserHQ ? niveis[condition] : nonHqNiveis[condition]) || [];

    if (isUserSDP) {
      options = options.filter((opt) => ["N6", "NP"].includes(opt));
    }

    setNivel(options[0]);

    return options.map((item) => ({ key: item, label: item, value: item }));
  }, [isUserHQ, isUserSDP, condition, setNivel]);

  return (
    <Container>
      <Select
        style={{ width: "12rem" }}
        name="condition"
        value={condition}
        options={conditionOptions}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
          setCondition(e.target.value)
        }
      />
      <Select
        style={{ width: "4rem" }}
        name="nivel"
        value={nivel}
        options={nivelOptions}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
          setNivel(e.target.value)
        }
      />
      <Select
        style={{ width: "10rem" }}
        name="segmento"
        value={segmento}
        options={[
          { key: 1, label: "BRF Regular", value: "1" },
          { key: 2, label: "BRF Pet", value: "2" },
        ]}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
          setSegmento(e.target.value)
        }
      />
    </Container>
  );
};

export default memo(SelectConditionNivel);
