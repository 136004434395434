import React, { useCallback, useEffect, useState } from "react";

import { useHistory, useLocation, useParams } from "react-router-dom";

import { Grid } from "@material-ui/core";
import { Form } from "@unform/web";
import LoadingScreen from "../../components/LoadingScreen";
import PageTitle from "../../components/PageTitle";
import Paper from "../../components/Paper";

import Button from "../../components/Button";
import Dropdown from "../../components/Dropdown";
import HeaderContainer from "../../components/HeaderContainer";
import Input from "../../components/Input";
import PageContainer from "../../components/PageContainer";
import { useToast } from "../../contexts/ToastContext";
import api from "../../services/api";
import InputChannel from "./InputChannel";
import {
  ChannelsTitle,
  Checkbox,
  CheckboxContainer,
  CheckboxLabel,
  GobackDiv,
  GoBackIcon,
  PaperHead,
  PaperTitle,
  SaveBtn,
} from "./styles";

interface UserProps {
  id: string;
  created_at: string;
  email: string;
  firstName: string;
  lastName: string;
  profile_type: string;
  ativo: string;
  profileName: string;
  registration: string;
  regions: Array<{
    idRegion: string;
    nameRegion: string;
  }>;
}

interface SelectedProfileData {
  id: string;
  value: string;
}

interface RegionProps {
  idRegion: string;
  nameRegion: string;
  selected?: boolean;
}

interface ParamProps {
  userId?: string;
}

interface StateProps extends UserProps {
  isEditUserPage?: boolean;
}

const UserPage: React.FC = () => {
  const [user, setUser] = useState<UserProps>({} as UserProps);
  const [userData, setUserData] = useState<UserProps>({} as UserProps);
  const [userEdit, setUserEdit] = useState<boolean>(false as boolean);

  const [regionsList, setRegionsList] = useState<RegionProps[]>(
    [] as RegionProps[]
  );

  const [profilesList, setProfilesList] = useState<SelectedProfileData[]>(
    [] as SelectedProfileData[]
  );
  const [selectedProfile, setSelectedProfile] = useState<SelectedProfileData>(
    {} as SelectedProfileData
  );

  // const [blockUser, setBlockUser] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const [channelId, setChannelId] = useState<any[]>([]);

  const { addToast } = useToast();
  const { state } = useLocation<StateProps>();
  const { userId } = useParams<ParamProps>();

  const history = useHistory();

  const loadData = useCallback(async () => {
    const { data: profileData } = await api.get("users/profile_type/list");
    const { data: regionsData } = await api.get("region/list");

    if (profileData && regionsData) {
      const displayProfilesList: SelectedProfileData[] =
        profileData.profiles.map((profile: any) => ({
          id: profile.id,
          value: profile.name,
        }));

      setProfilesList(displayProfilesList);

      const displayRegionsList: RegionProps[] = regionsData.regions;
      let foundRegionIndex;

      if (user.regions && user.regions.length > 0) {
        for (let i = 0; i < user.regions.length; i++) {
          foundRegionIndex = displayRegionsList.findIndex(
            (r: RegionProps) => r.idRegion === user.regions[i].idRegion
          );

          displayRegionsList[foundRegionIndex] = {
            ...user.regions[i],
            selected: true,
          };
        }
      }

      setRegionsList(displayRegionsList);
      setLoading(false);
    }
  }, [user.regions]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    async function loadUser() {
      const { data: userData } = await api.get(`users/${userId}/info`);

      if (userData) {
        setUserData(userData.user);
        setUser(userData.user);
        setChannelId(
          userData.user.channels.map((ch: any) => ({
            label: ch.name,
            value: ch.name,
          }))
        );
      }
    }

    if (userId !== "new") {
      loadUser();
    }
  }, [userId]);

  const handleInputChange = useCallback(
    async (e) => {
      const { value, id } = e.target;

      setUser((prevState) => ({ ...prevState, [id]: value }));

      if (state?.isEditUserPage && !userEdit) setUserEdit(true);
    },
    [userEdit, state?.isEditUserPage]
  );

  const handleCancelUserEdit = useCallback(() => {
    setUser(userData);
    setUserEdit(false);
  }, [userData]);

  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleCreateUser = useCallback(async () => {
    const formData = {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      profile_type: selectedProfile.id,
      registration: user.registration,
    };

    let err = false;
    let description = "";

    if (!formData.firstName || formData.firstName.length === 0) {
      description = "O primeiro nome não pode ser nulo.";
      err = true;
    }
    if (!formData.lastName || formData.lastName.length === 0) {
      description = "O último nome não pode ser nulo.";
      err = true;
    }
    if (!formData.email || formData.email.length === 0) {
      description = "E-mail não pode ser nulo.";
      err = true;
    }

    if (formData.email && !/^[\w.+\-]+@brf\.com$/.test(formData.email)) {
      description = "E-mail inválido.";
      err = true;
    }
    if (!formData.registration || formData.registration.length === 0) {
      description = "A matricula nome não pode ser nula.";
      err = true;
    }
    if (
      !userEdit &&
      (!formData.profile_type || formData.profile_type.length === 0)
    ) {
      description = "O perfil não pode ser nulo.";
      err = true;
    }

    if (err) {
      addToast({
        type: "error",
        title: "Erro",
        description,
      });
      return;
    }

    try {
      let response; // await api.post("users/add", formData);

      if (!userEdit) response = await api.post("users/add", formData);
      else
        response = await api.put("/users/update-user", {
          userId,
          ...formData,
        });

      if (response.data) {
        addToast({
          type: "success",
          title: "Sucesso",
          description: `Usuário ${
            userEdit ? "editado" : "cadastrado"
          } com sucesso.`,
        });

        if (!userEdit)
          history.replace(`/access/user/${response.data.info.id}`, {
            isEditUserPage: true,
          });
        else {
          setUser(response.data.user);
          setUserEdit(false);
        }
        return;
      }
    } catch (err) {
      addToast({
        type: "error",
        title: "Erro",
        description: `Ocorreu um erro ao ${
          userEdit ? "editar o" : "cadastrar um"
        } usuário.`,
      });
    }
  }, [user, userEdit, userId, selectedProfile, addToast, history]);

  const handleBlockUserChange = useCallback(async () => {
    try {
      setUser((prevState) => ({
        ...prevState,
        ativo: prevState.ativo === "1" ? "0" : "1",
      }));

      const response = await api.post("users/bloqued", {
        userId,
      });

      if (response.data) {
        addToast({
          type: "success",
          title: "Sucesso",
          description: response.data.info,
        });
      }
    } catch (err) {
      addToast({
        type: "error",
        title: "Erro",
        description: "error message",
      });
    }
  }, [userId, addToast]);

  // const handleRegistrationChange = useCallback(
  //   async (e) => {
  //     const { value } = e.target;

  //     setUser((prevState) => ({
  //       ...prevState,
  //       // [userId]: value,
  //       userId,
  //       registration: value,
  //     }));

  //     try {
  //       const response = await api.patch("/users/update-registration", {
  //         userId,
  //         registration: value,
  //       });

  //       if (response.data) {
  //         addToast({
  //           type: "success",
  //           title: "Sucesso",
  //           description: "Matrícula atualizada com sucesso",
  //         });
  //       }
  //     } catch (error) {
  //       addToast({
  //         type: "error",
  //         title: "Erro",
  //         description: "Erro ao atualizar a matrícula",
  //       });
  //     }
  //   },
  //   [userId, addToast]
  // );

  const handleSelectProfile = useCallback(
    async (profile) => {
      try {
        setSelectedProfile(profile);

        if (!state?.isEditUserPage) return;

        const response = await api.post("users/profile_type", {
          userId,
          profileId: profile.id,
        });

        if (response.data) {
          addToast({
            type: "success",
            title: "Sucesso",
            description: "Perfil Alterado com sucesso",
          });
        }
      } catch (err) {
        addToast({
          type: "error",
          title: "Erro",
          description: "error message",
        });
      }
    },
    [userId, state?.isEditUserPage, addToast]
  );

  useEffect(() => {
    if (!user.id) return;
    const url = "/users/update-channel";
    const request = {
      userId: user.id,
      channelId: channelId.map((ch: any) => ch.value),
    };
    api.post(url, request);
  }, [user.id, channelId]);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <PageContainer>
      <HeaderContainer>
        <GobackDiv>
          <GoBackIcon onClick={handleGoBack} />
          <PageTitle
            title={
              user.firstName
                ? user.lastName
                  ? user.firstName + " " + user.lastName
                  : user.firstName
                : "Nome do Usuário"
            }
          />
        </GobackDiv>
      </HeaderContainer>
      <Paper containerStyle={{ height: "100%", minHeight: "70vh" }}>
        <PaperHead>
          <PaperTitle>Dados</PaperTitle>
          {(!state?.isEditUserPage || userEdit) && (
            <SaveBtn width="20rem" onClick={handleCreateUser}>
              SALVAR
            </SaveBtn>
          )}
          {state?.isEditUserPage && userEdit && (
            <Button
              outline
              width="20rem"
              style={{ marginLeft: 10 }}
              onClick={handleCancelUserEdit}
            >
              CANCELAR
            </Button>
          )}
        </PaperHead>
        <Form onSubmit={() => {}}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Input
                id="firstName"
                name="firstName"
                disabled={state?.isEditUserPage}
                placeholder="Primeiro Nome"
                onChange={handleInputChange}
                value={user.firstName}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                id="lastName"
                name="lastName"
                disabled={state?.isEditUserPage}
                placeholder="Último Nome"
                onChange={handleInputChange}
                value={user.lastName}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                id="email"
                name="email"
                disabled={state?.isEditUserPage}
                placeholder="E-mail"
                onChange={handleInputChange}
                value={user.email}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                id="registration"
                name="registration"
                //disabled={!state?.isEditUserPage}
                placeholder="Matrícula"
                onChange={handleInputChange}
                value={user.registration}
              />
            </Grid>
            <Grid item xs={4}>
              <Dropdown
                selectedItem={{
                  id: user.profile_type,
                  value: user.profileName,
                }}
                setSelectedItem={handleSelectProfile}
                width="100%"
                containerStyle={{
                  width: "100%",
                  height: "100%",
                }}
                btnStyle={{
                  width: "100%",
                  height: "100%",
                }}
                placeholder="Tipo de Perfil"
                items={profilesList}
              />
            </Grid>
            {state?.isEditUserPage && (
              <Grid
                item
                xs={4}
                style={{ display: "flex", alignItems: "center" }}
              >
                <CheckboxContainer onClick={handleBlockUserChange}>
                  <Checkbox
                    color="primary"
                    checked={user.ativo !== "1" ? true : false}
                    disabled={false}
                  />
                  <CheckboxLabel>Bloquear usuário</CheckboxLabel>
                </CheckboxContainer>
              </Grid>
            )}
          </Grid>
        </Form>
        {state?.isEditUserPage && (
          <>
            <ChannelsTitle>Regional</ChannelsTitle>

            <Grid container spacing={3}>
              {regionsList.map((region) => (
                <Grid
                  key={region.idRegion}
                  item
                  xs={4}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <CheckboxContainerComponent
                    region={region}
                    userId={user.id}
                  />
                </Grid>
              ))}
            </Grid>

            <Grid container spacing={3}>
              <ChannelsTitle>Regionais Pet</ChannelsTitle>
              {[
                { idRegion: "1", nameRegion: "PET - BRF HQ", selected: false },
                {
                  idRegion: "2",
                  nameRegion: "PET - Nordeste",
                  selected: false,
                },
                {
                  idRegion: "3",
                  nameRegion: "PET - São Paulo Capital",
                  selected: false,
                },
                {
                  idRegion: "4",
                  nameRegion: "PET - São Paulo Interior",
                  selected: false,
                },
                { idRegion: "5", nameRegion: "PET - Sul", selected: false },
                {
                  idRegion: "6",
                  nameRegion: "PET - Sudeste ",
                  selected: false,
                },
                {
                  idRegion: "7",
                  nameRegion: "PET - Centro Oeste",
                  selected: false,
                },
                { idRegion: "8", nameRegion: "PET - Norte", selected: false },
              ].map((region) => (
                <Grid
                  key={region.idRegion}
                  item
                  xs={4}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <CheckboxContainerComponent
                    region={region}
                    userId={user.id}
                  />
                </Grid>
              ))}
            </Grid>

            {(selectedProfile.value === "Input SDP" ||
              user.profileName === "Input SDP") && (
              <>
                <ChannelsTitle>Input SDP</ChannelsTitle>
                <InputChannel
                  value={channelId}
                  setValue={setChannelId}
                  user={user}
                />
              </>
            )}
          </>
        )}
      </Paper>
    </PageContainer>
  );
};

interface CheckboxContainerProps {
  region: {
    idRegion: string;
    nameRegion: string;
    selected?: boolean;
  };
  userId: string;
}

const CheckboxContainerComponent: React.FC<CheckboxContainerProps> = ({
  region,
  userId,
}) => {
  const [checked, setChecked] = useState<boolean>(false);

  useEffect(() => {
    if (region.selected) {
      setChecked(region.selected);
    }
  }, [region]);

  const handleRegionChange = useCallback(
    async (regionId) => {
      try {
        setChecked((prevState) => !prevState);

        const response = await api.post("region/update-user", {
          userId,
          regionId,
        });

        if (response.data) {
          //   addToast({
          //     type: "success",
          //     title: "Sucesso",
          //     description: response.data.info,
          //   });
        }
      } catch (err) {
        // addToast({
        //   type: "error",
        //   title: "Erro",
        //   description: "error message",
        // });
      }
    },
    [userId]
  );

  return (
    <CheckboxContainer onClick={() => handleRegionChange(region.idRegion)}>
      <Checkbox color="primary" checked={checked} disabled={false} />
      <CheckboxLabel>{region.nameRegion}</CheckboxLabel>
    </CheckboxContainer>
  );
};

export default UserPage;
