import { memo, useState } from "react";

import Button from "../../../../../components/Button";
import Select from "../../../../../components/Select";
import { usePriceManagement } from "../../../hooks";
import { Container, Divider } from "./styles";

const Buttons: React.FC = () => {
  const { showComponent, navigate } = usePriceManagement();

  const [segmento, setSegmento] = useState("-1");

  if (showComponent !== "main") return null;
  return (
    <Container>
      <Select
        label="Segmento"
        name="segmento"
        options={[
          { key: 2, label: "Todos", value: "1" },
          { key: 3, label: "BRF Regular", value: "2" },
          { key: 4, label: "BRF Pet", value: "3" },
        ]}
        value={segmento}
        onChange={(e: any) => {
          setSegmento(e.target.value);
        }}
        style={{
          height: "40px",
          width: "12rem",
        }}
      />
      <Divider />
      <Button onClick={navigate.visualize}>Consultar</Button>
      <Divider />
      <Button
        containerStyle={{
          backgroundColor: "#dfac03",
          border: "1px solid #dfac03",
        }}
        onClick={navigate.upload}
      >
        Upload
      </Button>
      <Divider />
      <Button onClick={navigate.inputOptions}>Adicionar</Button>
    </Container>
  );
};

export default memo(Buttons);
