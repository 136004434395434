import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 2rem;

  div {
    max-height: 30px;
    background: white;
  }
`;
