import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  button {
    width: auto;
    padding: 0 2rem;
    height: 40px;
    flex-shrink: 0;
  }
`;

export const Divider = styled.div`
  height: 40px;
  margin: 0 2rem;

  border-left: 1px solid #ddd7e6;
`;
