import styled from "styled-components";

export const SelectWrapperStyled = styled.div<{
  width: string;
  filter: boolean;
  label: any;
}>`
  width: ${({ width }) => width};
  height: 50px;
  position: relative;
  border-radius: ${({ filter }) => (filter ? "24px" : "10px")};
  border: ${({ filter }) =>
    filter ? "1px solid #003b74" : "1px solid #ddd7e6"};
  margin-top: ${({ label }) => label && "32px"};

  label {
    position: absolute;
    top: -25px;
    font-size: 1.3rem;
    color: #003b74;
  }
  select {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    appearance: none;
    padding: 0 12px;
    border-radius: 24px;
    background: transparent;
    font-size: 1.3rem;
    color: ${({ filter }) => (filter ? "#003b74" : "#7E7E7E")};
    cursor: pointer;
  }
`;
